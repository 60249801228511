
import { defineComponent, reactive, toRefs } from 'vue'
// import demandApi from '@/axios/api/demand'
import demandApi from '@/axios/api/proposal'
import { Proposal } from '@/model/industryManagement/proposal'
import { Demand } from '@/model/industryManagement/demand'
import { useRoute, useRouter } from 'vue-router'
import { Notify } from 'vant'
import Data from '@/model/common/Data'
export default defineComponent({
  setup() {
    const onClickLeft = () => router.push('/submitRecord/list')
    const route = useRoute()
    const router = useRouter()
    const pageData = reactive({
      active: 0,
      fileList: [] as Data[],
      detailForm: {} as Proposal | Demand,
      loading: false,
    })
    pageData.loading = true
    demandApi
      .HisDetail({ demandNo: route.params.id })
      .then((res) => {
        if (res.demandInfoVO) {
          pageData.detailForm = res.demandInfoVO
          if ((res.demandInfoVO as Proposal).status === 0) {
            pageData.active = 0
          } else if (
            (res.demandInfoVO as Proposal).status === 1 ||
            (res.demandInfoVO as Proposal).status === 2 ||
            (res.demandInfoVO as Proposal).status === 3
          ) {
            pageData.active = 1
          } else if (
            (res.demandInfoVO as Proposal).status === 4 ||
            (res.demandInfoVO as Proposal).status === 5
          ) {
            pageData.active = 2
          } else {
            pageData.active = 0
          }
        } else {
          pageData.detailForm = res.proposalVO as Demand
          if (pageData.detailForm.dealStatus === 1) {
            pageData.active = 2
          } else {
            pageData.active = 0
          }
        }
        pageData.fileList = res.copFileInfoVOList?.map((item) => {
          //获取最后一个.的位置
          let index = (item.fullFileName as string).lastIndexOf('.')
          //获取后缀
          let ext = (item.fullFileName as string).substr(index + 1)
          console.log(ext)
          let isImage =
            ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(
              ext.toLowerCase()
            ) !== -1
          return {
            url: `/api/file/preview?id=${item.id}`,
            name: item.fullFileName,
            isImage,
          }
        }) as Data[]
        pageData.loading = false
      })
      .catch((err) => {
        pageData.loading = false
        Notify({ type: 'warning', message: err })
        if (err?.includes('未认证')) {
          router.push('/phoneValidation')
        }
      })
    const dow = (val: Data) => {
      if (!val.isImage) {
        let a = document.createElement('a')
        a.href = val.url as string
        a.setAttribute('download', val.name as string)
        a.click()
      }
    }
    return {
      ...toRefs(pageData),
      onClickLeft,
      dow,
    }
  },
})
